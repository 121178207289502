h1,
h2,
h3 {
  font-family: "Nanum Myeongjo", cursive;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
  background: transparent url(/static/media/bg.eee8ed13.jpg) top center;
  background-size: cover;
  background-attachment: fixed;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  background: #fff;
  border-radius: 3px;
}

@media (min-width: 769px) {
  .container {
    margin: 2.5rem auto;
  }
}

/* Home Component */

.home-list li {
  margin-bottom: 15px;
}
.home-button {
}

/* Result Component */
.result {
  padding: 1.5rem 2.5rem;
}

/* Quiz Component */
.answerOptions {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* QuestionCount Component */
.questionCount {
  padding: 1.5rem 2.5rem 0;
  font-size: 14px;
}

/* Question Component */
.question {
  margin: 0;
  padding: 0.5rem 2.5rem 1.5rem 2.5rem;
}

/* AnswerOption Component */
.answerOption {
  border-top: 1px solid #eee;
}

.answerOption:hover {
  background-color: #eefbfe;
}

.radioCustomButton {
  position: absolute;
  width: auto;
  opacity: 0;
}

.radioCustomButton,
.radioCustomLabel {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.radioCustomLabel {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 1.5rem 2.5rem 1.5rem 5rem;
  font-size: 16px;
  line-height: 1.5;
}

.radioCustomButton ~ .radioCustomLabel:before {
  position: absolute;
  top: 20px;
  left: 38px;
  width: 28px;
  height: 28px;
  content: "";
  display: inline-block;
  vertical-align: middle;
  background: #fff;
  border: 1px solid #bbb;
  border-radius: 50%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.radioCustomButton:checked ~ .radioCustomLabel:before {
  content: "";
  background: #8bc53f url(/static/media/icon-check.4cffa739.svg) no-repeat;
  background-size: 27px;
  border-color: #8bc53f;
}

/* Animation */
.fade-enter {
  opacity: 0;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  -webkit-transition: opacity 0.5s ease-in-out 0.3s;
  transition: opacity 0.5s ease-in-out 0.3s;
}

.fade-leave {
  position: absolute;
  top: 0;
  width: 100%;
  opacity: 1;
}

.fade-leave.fade-leave-active {
  opacity: 0;
  -webkit-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
}

.fade-appear {
  opacity: 0;
}

.fade-appear.fade-appear-active {
  opacity: 1;
  -webkit-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
}

.App-logo {
  -webkit-animation: spin infinite 20s linear;
          animation: spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  padding: 20px;
  color: white;
  text-align: center;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

